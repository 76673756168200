.redbackground {
  background-color: lightcoral;
  border: none;
  color: White;
}

.redbackground:hover {
  background-color: lightcoral;
  border: none;
}

.Signupw {
  padding: 50px 0px;
}

.Signup h3 {
  font-weight: 100;
  font-weight: 26px;
  line-height: 36px;
  color: #0b2e53;
  opacity: 0.6;
}

.Signup p {
  width: auto;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  color: #5b6a87;
  line-height: 1.8em;
  max-width: 600px;
  margin: auto;
  opacity: 1;
}

.form-wrapper {
  width: 100%;
  max-width: 500px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(17, 49, 96, 0.08) 0px 1px 3px,
    rgba(17, 49, 96, 0.08) 0px 0px 1px;
  padding: 32px;
  border-radius: 5px;
  margin: 50px auto;
  margin-bottom: 50px;
  margin-top: 0px;
  text-align: left;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.title {
  font-size: 18px !important;
}

.form-wrapper p {
  margin-bottom: 15px;
  font-size: 16px;
}

.control-label {
  float: left;
}

.form-control {
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #132042;
  font: normal 15px Open Sans, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.1px;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #d2dae2;
  border-radius: 6px;
  box-shadow: 0 0 3px 0 rgba(17, 49, 96, 0.06);
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, padding 0.12s linear, max-height 0.12s linear,
    -webkit-box-shadow 0.12s linear;
  -webkit-transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  transition: background-color 0.12s linear, color 0.12s linear,
    border-color 0.12s linear, box-shadow 0.12s linear, padding 0.12s linear,
    max-height 0.12s linear;
  min-height: 45px;
  padding-left: 15px;
}

.btn-primary:disabled {
  opacity: 0.5;
}

textarea {
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.progress-container {
  max-width: 500px;
  height: 10px;
  border: 1px solid #0085ef;
  margin: auto;
}

.progress {
  height: 100%;
  background-color: #0085ef;
  transition: 0.5s all ease;
  border-radius: 0px;
}

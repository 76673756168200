.Payment {
  text-align: left;
}

@media all and (min-width: 480px) {
  .Payment form {
    margin: 0 auto;
    max-width: 480px;
  }
}

.Settings {
}

.Settings h1 {
  font-size: 28px;
  line-height: 31px;
  color: #0b2e53;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Settings h3 {
  font-size: 18px;
  line-height: 33px;
  color: #0b2e53;
  margin: 0px;
  margin-bottom: 20px;
  font-weight: 600;
}

.Settings .pricing {
  padding: 0px 40px;
}

.choosePlanImg {
  cursor: pointer;
}

.Settings .form-wrapper p {
  text-align: center !important;
}
